.ContactItem{
    width: 100%;
    &:not(:last-child){
        margin-bottom: 1rem;
    }
    .contact{
        display: flex;
        align-items: center;
        background-color:#191D2B ;
        padding: 3rem 0;
        .right-items{
            margin-left: 2rem;
        }
        img{
            padding: 1rem;
            border: 1px solid #2e344e;
            margin-left: 2rem;
            width: 16%;
        }
        .right-items{
            h6{
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }
}

.ContactPage{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 6rem;
    @media screen and (max-width: 1270px){
        grid-template-columns: repeat(1, 1fr);
        .map-sect{
            width: 100%;
            height: 50vh;
            margin-bottom: 1rem;
        }
    }
    .contact-sect{
        width: 100%;
    }
}
.map-sect{
    width: 97%;
    padding: 1rem;
    background-color:#191D2B;
}

button, a {
    font-size: 1.2rem;
    color: #a4acc4;
    background-color: #191D2B;
    border: none;

    text-decoration: none;
    cursor: pointer;
}

form {
    input, textarea {
        background-color:rgb(56, 65, 97);
        border: none;
        color: white;

        width: 100%;
    }

    input {
        height: 25px;
    }

    textarea {
        height: 55px;
    }

    button {
        padding: .6rem 2rem;
        background-color: #0381ff5b;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        color: white;
        width: 100%;
        transition: all .3s ease-in-out;
        &:not(:last-child){
            margin-right: 1rem;
        }
        &:hover{
            background-color: #037FFF;
        }
        &:active{
            background-color: #037FFF;
        }
        &:focus{
            background-color: #037FFF;
        }
    }

    label {
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
        position: relative;
    }
}

.contact-sect{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.title{
    margin-bottom: 5rem;
}